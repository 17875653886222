import React from 'react';
import PropTypes from 'prop-types';
import { WeezHeader, Button } from '@weezevent/nacre';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { AttendeesList, StatsCardFetch } from '@weezevent/socium';
import { useAppContext } from '../../context/appContext';
import Attendee from '../../models/attendee';
import Inventory from '../../models/inventory';
import ProgressGuestsModel from '../../models/progressGuestsModel';
import PartnerForm from '../../models/partnerform';
import Rate from '../../models/rate';
import Booking from '../../models/booking';
import BatchStats from '../../models/batchStats';
import AttendeeCreationRequest from '../../models/attendeeCreationRequest';
import MailStatus from '../../models/mailStatus';
import Styles from '../../styles/components/invitations/invitations.module.css';

function StatsCardContainer({ partnerId, attendeeInProgress }) {
    return (
        <div className={Styles['stats-card-container']}>
            <StatsCardFetch partnerId={partnerId} attendeeInProgress={attendeeInProgress} batchStatsModel={BatchStats} />
        </div>
    );
}

StatsCardContainer.propTypes = {
    partnerId: PropTypes.string,
    attendeeInProgress: PropTypes.number
};

export default function Invitations({ partner }) {
    const { t } = useTranslation();
    const [attendeeInProgress, setAttendeeInProgress] = React.useState(0);
    const [{ user }] = useAppContext();
    const [openSideBar, setOpenSideBar] = React.useState(false);

    const headerProps = {
        rightComponent: [
            partner.settings.display_request_button && (
                <Button
                    key={'request_button'}
                    label={t('partners.invitation.request_button')}
                    onClick={() => {
                        window.open(partner.settings.request_url, '_blank');
                    }}
                />
            ),
            <Button
                key={'add_attendee'}
                primary
                icon={<Icon name="plus" />}
                label={t('partners.invitation.main_cta')}
                onClick={() => {
                    setOpenSideBar(true);
                }}
            />
        ]
    };

    return (
        <div className={Styles['invitations-list']}>
            <WeezHeader className={Styles['main-header']} title={t('partners.invitation.header')} {...headerProps}>
                {t('partners.invitation.subtitle')}
            </WeezHeader>
            <StatsCardContainer partnerId={partner.pk} attendeeInProgress={attendeeInProgress} />
            <div className={Styles['invitations_container']}>
                <AttendeesList
                    partner={partner}
                    addAttendee={openSideBar}
                    setAddAttendee={setOpenSideBar}
                    onAttendeeInProgress={setAttendeeInProgress}
                    user={user}
                    inventoryModel={Inventory}
                    attendeeModel={Attendee}
                    rateModel={Rate}
                    formModel={PartnerForm}
                    attendeeCreationRequestModel={AttendeeCreationRequest}
                    bookingModel={Booking}
                    batchStatsModel={BatchStats}
                    mailStatusModel={MailStatus}
                    progressGuestsModel={ProgressGuestsModel}
                />
            </div>
        </div>
    );
}

Invitations.propTypes = {
    partner: PropTypes.object.isRequired
};
